<!--
 * @Description: 商城页面
 * @Autor: WangYuan
 * @Date: 2021-06-10 16:01:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-26 00:29:07
-->
<template>
  <div class="body">
    <!-- 头部 -->
    <div class="flex">
      <div class="content-head flex">
        <img class="w70 h70 mr10 radius"
          src="https://img.yzcdn.cn/public_files/2016/05/13/8f9c442de8666f82abaf7dd71574e997.png!small.webp" alt="" />
        <div class="flex-column" style="width: 100%;">
          <div class="flex row-between mb7">
            <div class="flex col-center">
              <span class="mr8 f14 f-bold">{{ homePage ? homePage.name : '暂无首页' }}</span>
              <div class="p3 r3 bg-grey f12 f-dark-grey" style="border: 1px solid #c2bfbf;">全渠道首页</div>
            </div>
            <div v-if="homePage">
              <span class="f14 mr10 f-theme pointer" @click="editPage(homePage.pagesId)">编辑</span>
              <span class="f14 f-theme pointer" @click="replacePage(homePage)">更换</span>
            </div>
          </div>
          <span class="f12 f-dark-grey mb18">自主创建</span>
          <span class="f12 f-grey">最近修改：{{ homePage ? homePage.updateTime : '暂无首页' }}</span>
        </div>
      </div>

      <div class="content-head flex">
        <img class="w70 h70 mr10 radius"
          src="https://img.yzcdn.cn/public_files/2016/05/13/8f9c442de8666f82abaf7dd71574e997.png!small.webp" alt="" />
        <div class="flex-column" style="width: 100%;">
          <div class="flex row-between mb7">
            <div class="flex col-center">
              <span class="mr8 f14 f-bold">{{ personalPage ? personalPage.name : '暂无个人中心页' }}</span>
              <div class="p3 r3 bg-grey f12 f-dark-grey" style="border: 1px solid #c2bfbf;">个人中心页</div>
            </div>
            <div v-if="personalPage">
              <span class="f14 mr10 f-theme pointer" @click="editPage(personalPage.pagesId)">编辑</span>
              <span class="f14 f-theme pointer" @click="replacePage(personalPage)">更换</span>
            </div>
          </div>
          <span class="f12 f-dark-grey mb18">自主创建</span>
          <span class="f12 f-grey">最近修改：{{ personalPage.updateTime }}</span>
        </div>
      </div>
    </div>

    <!-- 页面列表 -->
    <div class="content">
      <el-button size="small f-white bg-theme" @click="addPage">新建页面</el-button>
      <el-button size="small f-white bg-theme" @click="bindPullPage">拉取页面</el-button>
      <div class="mt30" v-if="init">
        <el-table :data="dataList" border style="width: 100%" max-height="560"
          :header-cell-style="{ background: '#f7f8fa', color: '#323233', fontWeight: 500 }">
          <el-table-column prop="name" label="页面名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <input v-model="scope.row.name" @blur="autoSave(scope.row)" :title="scope.row.name"
                style="width: 70%; margin-right: 9px;"></input>
              <span>{{ scope.row.slot }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="最近修改时间" width="180"></el-table-column>
          <el-table-column prop="sign" label="槽位标识" width="180"></el-table-column>
          <el-table-column prop="state" label="页面状态" width="120">
            <template slot-scope="scope">
              <span>已创建</span>
              <!-- <span v-if="scope.row.state == 0">未发布</span>
                <span v-else-if="scope.row.state == 1">已发布</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="近30天浏览量" width="180"></el-table-column>
          <el-table-column prop="remote" label="备注" show-overflow-tooltip>
            <template slot-scope="scope">
              <input v-model="scope.row.remote" @blur="autoSave(scope.row)" :title="scope.row.remote"
                style="width: 93%;" />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" size="small">
            <template slot-scope="scope">
              <span class="mr5 f-theme pointer" @click="editPage(scope.row.pagesId)">编辑</span>
              <span class="mr5 f-theme pointer" @click="setSlot(scope.row)">设置槽位</span>
              <!-- <span class="ml5 mr5 f-theme pointer" @click="copyPage(scope.row)">复制</span> -->
              <span class="ml5 mr5 f-theme pointer" @click="preview(scope.row)">预览</span>
              <span v-show="scope.row.sign !== 'home' && scope.row.sign !== 'user'" class="ml5 mr5 f-theme pointer"
                @click="delPageDial(scope.row)">删除</span>

              <!-- placement="bottom" -->
              <el-popover width="130" trigger="hover" v-show="scope.row.sign !== 'home' && scope.row.sign !== 'user'">
                <div>
                  <div @click="setHomePage(scope.row)" class="popover-div">设为主页</div>
                  <div @click="setPersonPage(scope.row)" class="popover-div">设为个人中心</div>
                </div>
                <span class="el-dropdown-link" slot="reference" style="margin-left: 5px">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.current" :page-size="queryForm.size" :pager-count="5"
          :page-sizes="[10, 20, 50, 100, 200]" layout="total, sizes, prev, pager, next, jumper" :total="totalPage"
          background class="pagina mt15">
        </el-pagination>

      </div>

    </div>

    <!-- 更换首页 更换个人中心 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">
      <el-form :model="form" ref="from" :inline="true">
        <el-row shadow="hover">
          <el-form-item label="页面名称">
            <el-input v-model="form.name" placeholder="请输入页面名称" size="mini" style="width:250px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="ml10" icon="search" size="mini" type="success" @click="getTableList">查询</el-button>
            <el-button icon="Refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-row>
      </el-form>




      <el-table :data="gridData" border class="mb8"
        :header-cell-style="{ background: '#f7f8fa', color: '#323233', fontWeight: 500 }">
        <el-table-column property="name" label="页面名称">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
            <i v-show="scope.row.sign == 'home' && scope.row.home == 1" class="el-icon-s-home h16 w16 ml5 f-theme"></i>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button v-if="dialogTitle == '更换首页'" :disabled="scope.row.sign == 'home'" type="text"
              :style="scope.row.sign == 'home' ? 'color:#999' : ''" @click="setHomePage(scope.row)"
              class="mr5 f-theme pointer">设为首页</el-button>

            <el-button v-if="dialogTitle == '更换个人中心'" :disabled="scope.row.sign == 'user'" type="text"
              :style="scope.row.sign == 'user' ? 'color:#999' : ''" @click="setPersonPage(scope.row)"
              class="mr5 f-theme pointer">设为个人中心</el-button>


            <el-button @click="preview(scope.row)" class="ml5 mr5 f-theme pointer" type="text">预览</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span class="f-mini f-grey">共 {{ dialogTotal }} 条</span>
    </el-dialog>

    <!-- 更换槽位 -->
    <el-dialog class="slottable" title="设置槽位" :visible.sync="slotdialog" @close="slotDialogClose">
      <el-form :model="form" ref="form" :inline="true">
        <el-row shadow="hover">
          <el-form-item>
            <el-input v-model="form.name" placeholder="请输入页面名称" suffix-icon="el-icon-search" style="width:250px"
              @change="getSlotData"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.slotCategoryId" clearable placeholder="请选择" @change="getSlotData">
              <el-option v-for="item in treedata" :key="item.slotCategoryId" :label="item.name"
                :value="item.slotCategoryId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button style="margin-left: 30px;" @click="changeSlotrRefresh" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <el-table ref="multipleTable" :data="tableData" height="300" :header-cell-class-name="cellClass"
        style="width: 100%;overflow-y: auto;" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#f7f8fa', color: '#323233', fontWeight: 500 }">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="槽位名称"> </el-table-column>
        <el-table-column prop="sign" label="槽位标识"> </el-table-column>
        <el-table-column prop="slotCategoryName" label="槽位分类"> </el-table-column>
      </el-table>
      <div slot="footer" style="margin-top: 20px;" class="dialog-footer">
        <el-button @click="slotDialogClose">取 消</el-button>
        <el-button type="primary" :loading="slotloading" @click="slotDialogSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 拉取页面 -->
    <dep-drawer ref="drawer" title="拉取页面">
      <pull-page-index @bindClose="bindClose"></pull-page-index>
    </dep-drawer>

    <!-- 预览 -->
    <real-timeView :show.sync="previewShow" :previewRow="previewRow"></real-timeView>
  </div>
</template>

<script>
import RealTimeView from "@/components/TopBar/RealTimeView.vue";
import { mapMutations, mapGetters, } from "vuex";
import PullPageIndex from "./pullPage/index.vue";
import { getSlotCategoryList, querySlotList, getEditSlotPage } from "@/api/slot";
import { getFormPage, pageSave, shopUpdate, delFormPage,getProjectById } from "@/api/project";

export default {
  name: "mall-pages",
  data() {
    return {
      form: {
        name: '',
        slotCategoryId: ''
      },
      queryForm: {
        aid: '',
        size: 10,
        current: 1,
      },
      dataList: [],
      totalPage: 0,
      previewShow: false,
      previewRow: {},
      dialogTableVisible: false,
      dialogTitle: '更换首页',
      dialogTotal: 0,
      gridData: [],
      tabList: [],
      slotdialog: false,
      tableData: [],
      selectedRow: {},
      slotloading: false,
      treedata: [],
      pagesId: "",
      sign: '',
      init: false
    };
  },
  components: {
    RealTimeView,
    PullPageIndex,
  },
  computed: {
    ...mapGetters(["project"]),
    homePage() {
      return this.project.pagesEntityList.find((page) => page.home == 1);
    },
    personalPage() {
      return this.project.pagesEntityList.find((page) => page.home == 2);
    },
  },
  watch: {
    slotdialog(val) {
      if (val) {
        this.getSlotData()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      //判断是否是编辑跳转之后返回的
      console.log("sessionStorage.getItem('editJumpBeforeCurrent'):",sessionStorage.getItem('editJumpBeforeCurrent'));
      if (sessionStorage.getItem('editJumpBeforeCurrent')) {
        this.$set(this.queryForm, "current", parseInt(sessionStorage.getItem('editJumpBeforeCurrent')));
        sessionStorage.setItem('editJumpBeforeCurrent', '');
      }
     this.getDataList();
      this.init = true;
     this.getsSlotCategoryData()
    })
  },
  methods: {
    ...mapMutations(["setCurPage", "setProject"]),
    //设置重置
    changeSlotrRefresh(){
      this.form.name = ""
      this.form.slotCategoryId = ""
      this.getSlotData()
    },
    // 去除全选
    cellClass() {
      return 'selectAllbtnDis'
    },

    // 获取页面列表数据
    getDataList() {
      this.queryForm.aid = this.project.aid;
      getFormPage(this.queryForm).then(res => {
        this.dataList = res.data.records;
        this.totalPage = res.data.total;
      }).catch((err) => {
        this.$message.error(err.msg);
      });

      let query = {
        aid: this.project.aid,
        size: 1000,
        current: 1,
      }

      getFormPage(query).then(res => {
        this.project.pagesEntityList = res.data.records;
        this.gridData=res.data.records;
        this.setProject(this.project);

      }).catch((err) => {
        if(err.msg){
          this.$message.error(err.msg);
        }
      });


    },

    // 编辑 新增页面
    autoSave(row) {
      if (row.pagesId) {
        shopUpdate({ pagesId: row.pagesId, aid: row.aid, name: row.name, remote: row.remote }).then(res => {
          if (res.code == 0) {
            this.$message({ message: '编辑成功', type: 'success' });
          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });
      } else {
        pageSave({ pagesId: row.pagesId, aid: row.aid, name: row.name, remote: row.remote }).then(res => {
          if (res.code == 0) {
            this.$message({ message: '新建成功', type: 'success' });
            this.getDataList();
          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });
      }
    },

    // 每页数
    handleSizeChange(val) {
      this.queryForm.size = val;
      this.queryForm.current = 1;
      this.getDataList();
    },

    // 当前页
    handleCurrentChange(val) {
      this.queryForm.current = val;
      this.getDataList();
    },

    // 设置槽位
    setSlot(val) {
      console.log('val--------', val)
      this.slotdialog = true
      this.pagesId = val.pagesId
      this.sign = val.sign
    },

    // 获取槽位
    async getSlotData() {
      this.form.aid = this.$store.state.project.aid
      let { data } = await querySlotList(this.form);
      if (data) {
        this.tableData = data
        let row = null
        if (this.sign) {
          this.tableData.forEach(item => {
            if (item.sign == this.sign) {
              row = item
            }
          })
        }
        if (row) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, true)
          })
          this.selectedRow = row
        }
      }

    },

    // 获取槽位分类
    async getsSlotCategoryData() {
      let params = { aid: this.$store.state.project.aid }
      let { data } = await getSlotCategoryList(params);
      if (data) {
        this.treedata = this.handletreedata(data)
      }
    },

    // 数据转换
    handletreedata(treeNodes) {
      let result = [];
      function traverse(node) {
        const newNode = { ...node };
        delete newNode.children;
        result.push(newNode);
        if (node.children) {
          node.children.forEach(traverse);
        }
      }
      treeNodes.forEach(traverse);
      return result;
    },

    // 选择槽位
    handleSelectionChange(selectedRows) {
      this.$nextTick(() =>{
        if (selectedRows.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(selectedRows.pop());
      }
        this.selectedRow = selectedRows[0];
      })
      
    },

    // 槽位确认
    slotDialogSubmit() {
      this.slotloading = true
      let params = {};
      if (!this.selectedRow.sign) {
        this.$message({
          type: "warning",
          message: "请选择槽位",
        });
        this.slotloading = false
        return false
      }
      params.pagesId = this.pagesId
      params.sign = this.selectedRow.sign
      params.aid = this.$store.state.project.aid
      getEditSlotPage(params).then(res => {
        if (res.data) {
          this.$message({
            type: "success",
            message: "设置槽位成功",
          });
          this.getDataList()
          this.form.name = ""
          this.form.slotCategoryId = ""
          this.slotloading = false
          this.selectedRow = {}
          this.$refs.multipleTable.clearSelection();
          this.slotdialog = false
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
          this.$refs.multipleTable.clearSelection();
          this.slotloading = false
          this.selectedRow = {}
        }
      })
      this.slotloading = false
    },

    // 关闭槽位弹窗
    slotDialogClose() {
      this.form.name = ""
      this.form.slotCategoryId = ""
      this.slotdialog = false
      this.slotloading = false
      this.$refs.multipleTable.clearSelection();
    },

    // 关闭抽屉
    bindClose() {
      this.getDataList();
      this.$refs.drawer.show = false;
    },

    // 拉取页面
    bindPullPage() {
      this.$refs.drawer.show = true;
    },

    // 新增页面
    addPage() {
      let page = {
        // id: this.$getRandomCode(4),
        aid: this.project.aid,
        pagesId: '',
        name: "新增页面",
        componentEntityList: [],
        home: 0,
        sign: '',
      };
      this.autoSave(page);
    },

    // 复制页面
    copyPage(data) {
      let page = this.$cloneDeep(data);

      page.id = this.$getRandomCode(4);
      page.home = 0;

      this.project.pagesEntityList.push(page);
    },

    // 编辑页面
    editPage(id) {


      let page = this.project.pagesEntityList.find((page) => (page.pagesId == id));


      sessionStorage.setItem('curPage', JSON.stringify(page));
      sessionStorage.setItem('editJumpBeforeCurrent', this.queryForm.current);

      this.setCurPage(page);
      this.$router.push({ name: "page-build" });
    },

    // 删除
    delPageDial(row) {
      let _this = this;
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delFormPage([row.pagesId]).then(res => {
          if (res.code == 0) {
            _this.getDataList();
            _this.$message({ message: '删除成功', type: 'success' });
          }
        }).catch((err) => {
          _this.$message.error(err.msg);
        });
      });
    },

    // 更换首页和个人中心
    replacePage(item) {
      this.gridData = this.project.pagesEntityList;
      if (item.home == 1 && item.sign == 'home') {
        this.dialogTitle = '更换首页';
        this.gridData = this.gridData.filter(row => row.sign !== 'user');
        this.dialogTotal = this.gridData.length;
      } else if (item.home == 2 && item.sign == 'user') {
        this.dialogTitle = '更换个人中心';
        this.gridData = this.gridData.filter(row => row.sign !== 'home');
        this.dialogTotal = this.gridData.length;
      }
      this.dialogTableVisible = true;
    },

    // 设置主页
    setHomePage(data) {

    /*  this.project.pagesEntityList.forEach((page) => {
        if (page.home == 1) page.home = 0;
        if (page.sign == 'home') page.sign = '';
      });*/

      data.home = 1;
      data.sign = 'home';
      this.changePage(data);

    },
    // 设置个人中心
    setPersonPage(data) {
     /* this.project.pagesEntityList.forEach((page) => {
        if (page.home == 2) page.home = 0;
        if (page.sign == 'user') page.sign = '';
      });*/
      data.home = 2;
      data.sign = 'user';
      this.changePage(data);
    },

    // 设置主页||设置个人中心 调用接口
    changePage(data) {
      let item = {
        pagesId: data.pagesId,
        sign: data.sign,
        aid: data.aid
      }

      getEditSlotPage(item).then(res => {
        if (res.code == 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.getDataList();


         console.log("this.gridData = this.project.pagesEntityList;:",this.project.pagesEntityList);

        }
      }).catch((err) => {
        this.$message.error(err.msg);
      });

    },

    // 预览 
    preview(row) {
      this.previewShow = true;
      this.previewRow = row;
    },

    getTableList() {
      if (this.form.name == '' && this.dialogTitle == '更换首页') {
        this.gridData = this.project.pagesEntityList.filter(row => row.home != 2);
        this.dialogTotal = this.gridData.length;
      } else if (this.form.name == '' && this.dialogTitle == '更换个人中心') {
        this.gridData = this.project.pagesEntityList.filter(row => row.home != 1);
        this.dialogTotal = this.gridData.length;
      } else {
        // this.gridData = this.gridData.filter(row => row.name == this.form.name);
        this.gridData = this.gridData.filter(row => row.name.toLowerCase().includes(this.form.name.toLowerCase()));
        this.dialogTotal = this.gridData.length;
      }
    },

    // 清空搜索
    resetQuery() {
      this.form = {
        name: '',
      }
      this.getTableList();
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 4px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px !important;


}

::v-deep .selectAllbtnDis .cell .el-checkbox__inner {
  display: none;
}

input {
  border: 0;
  font-size: 13px;
  /*no*/
}

.body {
  padding: 15px;
  /*no*/

  .content {
    width: 100%;
    /*no*/
    height: 790px;
    /*no*/
    padding: 20px;
    /*no*/
    background: #fff;
  }

  .content-head {
    flex: 1;
    align-items: flex-start;
    padding: 20px 30px;
    /*no*/
    margin: 5px 5px 15px;
    background: #fff;
  }

  .pagina {
    text-align: right;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #155bd4;
}

.second-title {
  width: 160px;
  font-weight: 600;
  padding: 12px;
}

.title-icon {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 600;
}

.addBtn {
  color: #409eff;
  margin-top: -3px;
  border-radius: 5px;
  border: 1px solid #409eff;
}

.label-icon {
  transform: rotate(90deg);
  margin-right: 19px;
}

.popover-div {
  list-style: none;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #606266;
  outline: 0;
  cursor: pointer;

  &:hover {
    background-color: #ecf5ff;
    color: #66b1ff;
  }
}

::v-deep .el-tabs--left .el-tabs__header.is-left {
  width: 160px !important;

  .el-tabs__item {
    padding: 0;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
