import request from "@/utils/request";
/**
 * 槽位管理分页列表
 */
export const getSlotList = data => {
  return request({
    url: "easyapp/slot/page",
    method: "get",
    params: data,
  });
};
/**
 * 槽位列表查询
 */
export const querySlotList = data => {
  return request({
    url: "easyapp/slot/getList",
    method: "get",
    params: data,
  });
};
/**
 * id查询槽位
 */
export const getIdSlot = data => {
  return request({
    url: `easyapp/slot/${data} `,
    method: "get",
  });
};
/**
 * 新增槽位
 */
export const getAddSlot = async data => request({ url: "easyapp/slot", method: "POST", data });
/**
 * 修改槽位
 */
export const getEditSlot = data => {
  return request({
    url: "easyapp/slot",
    method: "put",
    params: data,
  });
};
/**
 * 删除槽位
 */
export const getDeleteSlot = async data => request({ url: "easyapp/slot", method: "DELETE", data });
/**
 * 分类查询树状图
 */
export const getSlotCategoryList = data => {
  return request({
    url: "easyapp/slotCategory/getAllList",
    method: "get",
    params: data,
  });
};
/**
 *
 * 新增槽位分类
 */
export const getAddSlotType = async data => request({ url: "easyapp/slotCategory", method: "POST", data });
/**
 *
 * 编辑槽位分类
 */
export const getEditSlotType = async data => request({ url: "easyapp/slotCategory", method: "PUT", data });
/**
 *
 * 修改槽位页面
 */
export const getEditSlotPage = async data => request({ url: "easyapp/pages/updateSign", method: "PUT", data });


/**
 * 删除槽位分类
 */
export const getDeleteSlotType = async data => request({ url: "easyapp/slotCategory", method: "DELETE", data });
