<template>
  <div class="drawer drawer__body">
    <div class="drawer__header">
      <span class="drawer__headerSpan"></span>拉取页面
    </div>

    <toolbar @refreshTable="getDataList" :showSearch.sync="showSearch"></toolbar>

    <div class="flex justify-between" v-loading="dataListLoading" element-loading-text="数据加载中...">
      <!-- 分类 -->
      <ul style="overflow: auto;max-height: 700px;">
        <li v-for="(item, index) in categoryList" :key="index" class="first-leve"
          :class="searchForm.aid == item.aid ? 'active-Scon' : ''" @click="bindClassify(item)">
          <div style="font-weight: 600;">{{ item.name }}</div>
          <div class="f14">{{ item.descr }}</div>
        </li>
      </ul>

      <div class="divider"></div>

      <!-- 列表数据 -->
      <div style="width: calc(100% - 350px);">
        <el-table ref="multipleTable" :data="dataList" size="mini" stripe @selection-change="handleSelectionChange"
          max-height="700">
          <el-table-column type="selection" width="55">
            <template slot-scope="scope">
              <input type="checkBox" :checked="pagesIds.includes(scope.row.pagesId)"
                @click="btnClickRow(scope.row.pagesId)" />
            </template>
          </el-table-column>
          <el-table-column label="页面名称" prop="name">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
              <i v-show="scope.row.home == 1" class="el-icon-s-home h16 w16 ml5 f-theme"></i>
              <i v-show="scope.row.home == 2" class="el-icon-s-custom h16 w16 ml5 f-theme"></i>
            </template>
          </el-table-column>
          <el-table-column label="标识" prop="sign"></el-table-column>
          <el-table-column label="最近修改时间" prop="updateTime"></el-table-column>
          <el-table-column label="页面状态" prop="status">
            <template slot-scope="scope">
              <span>已创建</span>
              <!-- <span>已发布</span> -->
              <!-- <span>未发布</span> -->
            </template>
          </el-table-column>
        </el-table>

        <div class="avue-crud__pagination">
          <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
            :current-page="searchForm.current" :page-sizes="[10, 20, 50, 100]" :page-size="searchForm.size"
            :total="totalPage" background layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
    </div>

    <div class="drawer__footer">
      <el-button size="medium" @click="$emit('bindClose')">取消</el-button>
      <el-button size="medium" type="primary" @click="bindSave()">确认获取</el-button>
    </div>

    <!-- 拉取提示 -->
    <el-dialog v-if="dialogVisible" title="提示" :visible.sync="dialogVisible" width="25%" :modal="false" top="35vh"
      :close-on-click-modal="false">
      <div class="flex items-center f16">
        <i class="el-icon-warning pull_dialog_icon"></i>
        确认是否需要拉取对应的槽位及槽位分类
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogVisible = false">取消</el-button>
        <el-button size="medium" :loading="loading" type="primary" @click="bindSave(1)">不需要拉取</el-button>
        <el-button size="medium" :loading="loading" type="primary" @click="bindSave(2)">需要拉取</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { shopGetList, getPagesPullPage, getPagesCheckPull, postSavePull } from "@/api/pullPage";
export default {
  name: "pull-page",
  data() {
    return {
      loading: false, // 按钮加载
      showSearch: true, // 展示不展示搜索
      pagesIds: [], // 页面选中的ids
      categoryList: [], // 分类列表
      searchForm: {
        size: 10,
        current: 1,
      }, // 搜索信息
      dataList: [], // 列表数据
      totalPage: 0, // 总数量
      dialogVisible: false, // 弹窗显示隐藏
      dataListLoading: false, // 列表加载
    }
  },

  created() {
    this.getClassifyList();
  },
  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    // 获取分类类别
    getClassifyList() {
      this.dataListLoading = true;
      shopGetList().then(res => {
        this.categoryList = res.data;
        this.bindClassify(this.categoryList[0]);
      }).catch(() => {
        this.$message.error(err.msg);
        this.dataListLoading = false;
      });
    },

    // 选择分类
    bindClassify(val) {
      if (this.searchForm.aid == val.aid) return;

      this.searchForm.aid = val.aid;
      this.getDataList();
    },

    // 获取页面列表
    getDataList() {
      this.dataListLoading = true;
      getPagesPullPage(this.searchForm).then((response) => {
        if (response.data) {
          this.dataList = response.data.records;
          this.totalPage = response.data.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      }).catch(() => {
        this.$message.error(err.msg);
        this.dataListLoading = false;
      });
    },

    // 选中
    btnClickRow(pagesId) {
      let index = this.pagesIds.indexOf(pagesId);
      if (index !== -1) {
        this.pagesIds.splice(index, 1);
      } else {
        this.pagesIds.push(pagesId);
      }
    },

    // 全选
    handleSelectionChange(val) {
      if (val && val.length) {
        let list = this.dataList.filter((a) => !this.pagesIds.includes(a.pagesId))
        let ids = list.map((a) => a.pagesId);
        this.pagesIds = [...ids, ...this.pagesIds];
      } else {
        let list = this.dataList.map((a) => a.pagesId);
        this.pagesIds = this.pagesIds.filter((a) => !list.includes(a));
      }
    },

    // 确认拉取
    async bindSave(scope) {
      console.log({
        aid: this.project.aid, scope, pagesIdList: this.pagesIds,
      });

      if (this.pagesIds?.length == 0) {
        this.$message.error('请选择拉取的页面');
        this.loading = false;
        return;
      }

      if (!scope) {
        this.dialogVisible = true;
        return;
      }

      let info = {
        aid: this.project.aid, scope, pagesIdList: this.pagesIds,
      }

      if (scope == 2) {
        let resp = await getPagesCheckPull(info);
        let _this = this
        if (resp.code == 1) {
          this.$message.error(resp.msg);
          return
        }

        if (resp.code == 2) {
          _this.$confirm(resp.msg + ' 是否拉取?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.PostSavePull({ ...info, isForceSlot: 2 })
          });
          return
        }

      }


      this.PostSavePull(info)
    },

    PostSavePull(info) {
      this.loading = true;
      postSavePull(info).then(res => {
        this.$emit('bindClose');
        this.$message.success('拉取成功');
        this.dialogVisible = false;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },


    // 每页数
    sizeChangeHandle(val) {
      this.searchForm.size = val;
      this.searchForm.current = 1;
      this.getDataList();
    },

    // 当前页
    currentChangeHandle(val) {
      this.searchForm.current = val;
      this.getDataList();
    },
  },
}
</script>

<style lang="scss" scoped>
.divider {
  width: 1px;
  height: auto;
  background-color: #f5f5f5;
  margin: 0 20px;
}

.pull_dialog_icon {
  font-size: 28px;
  color: #56a9ff;
  margin-right: 15px;
}

.first-leve {
  width: 300px;
  min-height: 33px;
  line-height: 33px;
  padding: 0 20px;
  cursor: pointer;
  word-break: break-all;
}

.active-Scon {
  color: rgb(56, 148, 255);
  background: rgb(239, 250, 255);
  border-radius: 5px;
}
</style>