/*
 * @Description: 拉取页面api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:55:04
 */
import request from '@/utils/request';

/**
 * 获取当前租户应用列表
 */
export const shopGetList = async (params) => request({ url: "easyapp/shop/getList", method: "get", params });


/**
 * 获取应用下槽位页面
 */
export const getPagesPullPage = async (params) => request({ url: "easyapp/pages/pullPage", method: "get", params });


/**
 * 校验页面是否存在
 */
export const getPagesCheckPull = async (data) => request({ url: "easyapp/pages/checkPull", method: "POST", data });


/**
 * 页面拉取保存
 */
export const postSavePull = async (data) => request({ url: "easyapp/pages/savePull", method: "POST", data });
